


import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import { TContact } from '@/_types/contact.type';

enum StatisticsTab {
  PERSONAL = 'personal',
  COMPANY = 'company',
  EVENT = 'event',
}
@Component({
  components: {
    PromoSideBarLeft,
    PromoSideBarRight,
  },
})
export default class Statistics extends Vue {

  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('_eventStore/event') event: TEvent;

  public StatisticsTab: typeof StatisticsTab = StatisticsTab;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get currentTab(): StatisticsTab {
    switch (this.$route.name) {
      case 'result-company':
        return StatisticsTab.COMPANY;

      case 'result-event':
        return StatisticsTab.EVENT;

      case 'result-personal':
      default:
        return StatisticsTab.PERSONAL;
    }
  }

  public get isEventCreator(): boolean {
    if (!this.event || !this.myself) {
      return false;
    }
    return this.event.creator_user_id === this.myself.user_id;
  }

  public get canSeeEventStatistics(): boolean {
    const eventEditors: TContact[] = (this.event && this.event.editors) || [];
    const isEventEditor: boolean = (this.myself && !!eventEditors.find((editor: TContact) => editor.id === this.myself.id));
    return this.isEventCreator || isEventEditor;
  }

  public get isCompanyTabVisible(): boolean {
    return this.myself && !!this.myself.promopage_external_id;
  }

}
